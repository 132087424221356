import WebGLApp from '../webgl/lib/WebGLApp'
import assets from '../webgl/lib/AssetManager'
import GGlab from '../webgl/scene/Gglab'

import * as THREE from 'three'
import * as Hammer from 'hammerjs'

import { fetchDailyTrends, dailyText } from '../utils/trends.js'
import { checkRotation } from '../utils'

window.DEBUG = window.location.search.includes('debug')

// grab our canvas
const canvas = document.querySelector('#app')

const hammer = new Hammer(canvas);
const Press = new Hammer.Press({
  time: 100
});
hammer.add(Press)

// setup the WebGLRenderer
const webgl = new WebGLApp({
  canvas,
  backgroundAlpha: 1,
  // show the fps counter from stats.js
  showFps: window.DEBUG,
  moveCam: false,
  orbitControls: true,
  raycaster: true,
})

// attach it to the window to inspect in the console
if (window.DEBUG) {
  window.webgl = webgl
}

// hide canvas
webgl.canvas.style.visibility = 'hidden'

export function addNaturalLight(camera = null) {
  const hemiLight = new THREE.HemisphereLight(
    'white',
    'darkslategrey',
    1,
  )

  const dirLight = new THREE.DirectionalLight(0xffffff, 1.05)
  dirLight.position.set(-0.1, 0, 0.25)
  dirLight.position.multiplyScalar(50)

  return {
    dirLight,
    hemiLight
  }

}

const getInitialCamPos = (media) => {
  console.log(media)
  switch (media) {
  }
}

// load any queued assets
assets.load({ renderer: webgl.renderer })
  .then(async () => {

    const { dirLight, hemiLight } = addNaturalLight(webgl)

    webgl.scene.add(dirLight, hemiLight)

    const dailyTrends = await fetchDailyTrends()

    document.querySelector('#text').innerHTML = dailyText(dailyTrends)

    // show canvas
    webgl.canvas.style.visibility = ''
    webgl.renderer.setClearColor( 0x000000, 1);

    webgl.camera.position.set(0, 0, 10)

    if (window.matchMedia('(max-width: 768px)').matches) {
      webgl.camera.position.set(0, 0, 15)
    }

    if (window.matchMedia('(max-width: 414px)').matches) {
      webgl.camera.position.set(0, 0, 20)
    }

    webgl.scene.gglab = new GGlab(webgl)

    const {
      gglab,
    } = webgl.scene

    webgl.scene.add(gglab)

    let rotation = 0

    webgl.scene.update = (dt) => {
      checkRotation(webgl.camera, webgl.scene, 0.005)
      dirLight.position.copy(webgl.camera.position)
    }

    // start animation loop
    webgl.start()
    webgl.draw()

    document.addEventListener('mousemove', onDocumentMouseMove, false)
  })

function onDocumentMouseMove(event) {
  event.preventDefault()
  webgl.mouse.x = (event.clientX / window.innerWidth) * 2 - 1
  webgl.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1
}

export const init = (models) => {

}

