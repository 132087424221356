import * as THREE from 'three'

import assets from '../lib/AssetManager'

const key = assets.queue({
  url: 'assets/GGlab.glb',
  type: 'gltf',
})

// basic three.js component example

export default class GGlab extends THREE.Group {
  constructor(webgl, options) {
    super(options)
    // these can be used also in other methods
    this.webgl = webgl
    this.options = options


    const modelGltf = assets.get(key)

    const planeMesh = new THREE.PlaneBufferGeometry(600, 200, 32)
    const planeMaterial = new THREE.MeshBasicMaterial({
      side: THREE.DoubleSide,
      //color: 'red',
      color: 0xff2222,
      transparent: true,
      opacity: 0
    })

    const plane = new THREE.Mesh(planeMesh, planeMaterial)
    plane.position.x = 300
    plane.position.y = 75

    this.gglab = modelGltf.scene.children[0]

    this.gglab.add(plane)

    this.gglab.position.x = -2.75
    this.gglab.position.y = -0.25
    this.gglab.rotation.x = THREE.MathUtils.degToRad(-15)
    this.gglab.rotation.y = THREE.MathUtils.degToRad(10)
    this.gglab.updateMatrix ()
    this.gglab.userObject = true

    this.add(this.gglab)

  }
}
